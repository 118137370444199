/* eslint-disable react/no-unused-prop-types */
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { ProductsJson, ProductsQuery } from '../../graphql-types';
import { imageWrapper } from './productImage.module.css';

type IProduct = Pick<
  ProductsJson,
  'id' | 'packages' | 'title' | 'slug' | 'prices' | 'description' | 'aboveTitle'
> & {
  image: { childImageSharp: { gatsbyImageData: IGatsbyImageData } };
};

interface ProductListProps {
  products: IProduct[];
  selected: string;
  onSelect: (newIdx: string) => void;
}

const Product = ({ aboveTitle, title, slug, image, packages }: IProduct) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-wrap">
      <div
        className={`${imageWrapper} flex md:h-auto w-full md:w-1/2 2xl:w-1/3 md:max-h-screen relative`}
      >
        <GatsbyImage
          image={image.childImageSharp.gatsbyImageData}
          alt={title}
          sizes="(min-width: 1536px) 33vw, (min-width: 768px) 50vw, 100vw"
        />
      </div>
      <div className="w-full md:w-1/2 2xl:w-2/3 py-8 md:pt-44 bg-gray-lighter px-4 md:px-16 text-center md:text-left">
        {aboveTitle && <h2 className="above-title-text mb-6">{aboveTitle}</h2>}
        <h1 className="title-text mb-10">{title}</h1>
        <p className="body-text mb-12">{t(`products.${slug}_description`)}</p>
        {packages.length > 0 && (
          <>
            <h2 className="above-title-text mt-16 mb-6">
              {t('products.packaging')}
            </h2>
            <div className="body-text text-gray-medium">
              {packages.join(' | ')}
            </div>
          </>
        )}
        {/* {prices.length > 0 && (
        <>
          <h2 className="above-title-text mt-16 mb-6">Cijene</h2>
          {prices.map((price, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={idx} className="body-text text-gray-medium">
              {price}
            </div>
          ))}
        </>
      )} */}
      </div>
    </div>
  );
};

const ProductList = ({
  products,
  onSelect,
}: ProductListProps): ReactElement => (
  <div className="flex justify-around">
    {products.map((product) => (
      <button
        key={product.slug}
        type="button"
        className="font-sans text-xl px-2 py-4 text-gray-dark hover:text-gray-darker cursor-pointer"
        onClick={() => onSelect(product.slug)}
      >
        {product.title}
      </button>
    ))}
  </div>
);

const productsQuery = graphql`
  query Products {
    allProductsJson {
      nodes {
        id
        image {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              breakpoints: [480, 640, 768, 1024, 1280, 1536]
              quality: 90
              placeholder: BLURRED
            )
          }
        }
        packages
        title
        slug
        prices
        description
        aboveTitle
      }
    }
  }
`;

export default function Products(): ReactElement {
  const q = useStaticQuery<ProductsQuery>(productsQuery);
  const products = q.allProductsJson.nodes as IProduct[];
  const [selectedSlug, setSelectedSlug] = useState<string>(products[0].slug);
  //   const next = () => {
  //     setSelectedSlug(selectedSlug + 1);
  //   };
  //   const prev = () => {
  //     setSelectedSlug(selectedSlug - 1);
  //   };
  //   const hasNext = () => selectedSlug < products.length;
  //   const hasPrev = () => selectedSlug > 0;

  const product = products.find(({ slug }) => slug === selectedSlug);
  if (!product) {
    throw new Error(`Can't find product with idx ${selectedSlug}`);
  }

  return (
    <div className="flex flex-col relative bg-gray-lighter">
      <div className="w-full md:w-1/2 2xl:w-2/3 md:px-4 ml-auto md:absolute md:pt-12 top-0 right-0">
        <ProductList
          products={products}
          selected={selectedSlug}
          onSelect={setSelectedSlug}
        />
      </div>
      <Product {...product} />
    </div>
  );
}
