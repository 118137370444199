import React, { ReactElement } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { HeroProductsQuery } from '@/../graphql-types';
import { heroImg, heroImgWrapper } from '@/styles/hero.module.css';

const HeroProducts = (): ReactElement => {
  const { desktop } = useStaticQuery<HeroProductsQuery>(graphql`
    query HeroProducts {
      desktop: file(relativePath: { eq: "hero-products.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
  `);

  return (
    <GatsbyImage
      image={desktop.childImageSharp.gatsbyImageData}
      alt="Boce ulja"
      loading="eager"
      className={heroImgWrapper}
      imgClassName={heroImg}
    />
  );
};

export default HeroProducts;
