import React, { ReactElement } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { graphql } from 'gatsby';
import BgImage from '@/components/BgImage';
import NavBar from '@/components/NavBar';
import Footer from '@/components/Footer';

import Products from '@/components/Products';
import Head from '@/components/Head';
import HeroProducts from '@/components/heroes/HeroProducts';

export default function ProductsPage(): ReactElement {
  const { t } = useTranslation();

  return (
    <>
      <Head title={`${t('products.products')} | ${t('kota_olive_oil')}`} />

      <NavBar />
      {/* Hero with it's own bg image */}
      <main className="h-9/16 md:h-screen" style={{ paddingTop: '60px' }}>
        <div className="w-full h-full d-flex relative -z-1">
          {/* Fix for borked lighthouse tooling expecting content and failing to give score */}
          <div className="absolute left-0 top-0" aria-hidden>
            Kota
          </div>
          <HeroProducts />
        </div>
      </main>

      {/* Everything that will have the bg image goes here, BgImage is contained here */}
      <div className="relative">
        <BgImage />
        <section className="py-16 md:py-32 lg:py-48">
          <div className="flex flex-col max-w-screen-xl px-4 lg:px-16 mx-auto w-full items-center text-center md:text-left">
            <h2 className="above-title-text text-gray-light mb-6">
              {t('products.products_abovetitle')}
            </h2>
            <h1 className="title-text text-center mb-10">
              {t('products.products_title')}
            </h1>

            <p className="body-text text-gray-light text-center mb-8">
              {t('products.products_text1')}
            </p>
            <p className="body-text text-gray-light text-center mb-8">
              {t('products.products_text2')}
            </p>
          </div>
        </section>

        <section className="flex w-full flex-wrap-reverse">
          <Products />
        </section>
      </div>

      <Footer />
    </>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
